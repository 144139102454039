import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";

const Wrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;

  h1, h2 {
    text-align: center;
  }
`;

const NotFoundPage = ({location}) => {
    return (
      <Layout location={location}>
        <SEO title='404: Not Found' />
        <Wrapper>
          <div className="row GoogleRow">
            <ins className="adsbygoogle GoogleAdsLeaderboard"
              style={{display:'block', width:'100%', maxHeight:'90px', marginBottom:'16px'}}
              data-ad-client="ca-pub-1001229702754753"
              data-ad-slot="9794187245"
              
              ></ins>
          </div>    
          <h1 className="heavytext NavBarGap">404</h1>
          <h2 className="heavytext">Oops! We can't find the page you're looking for.</h2>
          <div className="row GoogleRow">
            <ins className="adsbygoogle GoogleAdsLeaderboard"
              style={{display:'block', width:'100%', maxHeight:'90px', marginBottom:'16px'}}
              data-ad-client="ca-pub-1001229702754753"
              data-ad-slot="8317454043"
              
              ></ins>
          </div>
        </Wrapper>
      </Layout>
    )
}

export default NotFoundPage
